import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../../components/List'
import LayoutListView from '../../components/LayoutListView'

const resourcesPaid = ({ location }) => {
  const configID = '5OxDchQHLHqmWe4akOXjyw'
  const queryData = useStaticQuery(graphql`
    {
      allContentfulResources(filter: { resourcestore: { eq: true } }) {
        edges {
          node {
            id
            category
            title
            slug
            excerpt
            resourceUrl
            attachment {
              file {
                url
                fileName
              }
            }
            image {
              file {
                url
              }
            }
            body {
              json
            }
            createdAt(formatString: "MMMM Do YYYY")
          }
        }
      }
    }
  `)

  const TYPE = 'Resource Store'
  const IMAGE_HEIGHT = 150
  const data = queryData.allContentfulResources.edges.map(obj => ({
    id: obj.node.id,
    title: obj.node.title,
    body: obj.node.excerpt,
    image: obj.node.image,
    imageHeight: IMAGE_HEIGHT,
    slug: `/resources/${obj.node.slug}`,
    category: obj.node.category,
    type: TYPE
  }))

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <List data={data} itemType="subMenu" className="items-container" />
    </LayoutListView>
  )
}

export default resourcesPaid
